import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import ErrorHandler from '../error/error-handler-component';

export type Properties = {
	t: any;
	value: string;
	onOffsetChange: (e: any) => any;
	item?: any;
	isRequired?: boolean;
	isValid?: boolean;
	type: string;
};

const OffsetInput: React.FunctionComponent<Properties> = (props) => {
	const { t, value, onOffsetChange, isRequired, isValid, item, type } = props;

	return (
		<FormGroup data-qa='offset-select'>
			<Label>{t('offset')}:</Label>
			{isRequired && <span className='text-danger'>*</span>}
			<DebounceInput
				data-qa='offset-select'
				type='number'
				id={`${type}-offset-select`}
				className='form-control'
				value={value ? value : ''}
				min={1}
				placeholder={t('select_offset')}
				debounceTimeout={500}
				onChange={(event: any) => onOffsetChange(event.target.value)}
			/>
			<FormFeedback>
				{t('field_is_required')} {item && item.id && value && value === ''}
			</FormFeedback>
			{!isValid && (!value || value === '') && <ErrorHandler errorMessage='field_is_required' />}
		</FormGroup>
	);
};

export default OffsetInput;
